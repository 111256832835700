import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewEncapsulation
} from '@angular/core';
import { NotificationService } from 'app/shared/services/notification.service';
import { BusinessUnit } from 'app/shared/models/business-unit.model';
import { BusinessUnitsService } from 'app/shared/services/business-units.service';
import { take } from 'rxjs/operators';
import { Constants } from 'app/shared/config/constants';
import {
  ClientService,
  UserService
} from 'app/shared/services';

@Component ( {
  selector: 'reporter-business-unit-tree',
  templateUrl: './business-unit-tree.component.html',
  styleUrls: [ './business-unit-tree.component.scss' ],
  encapsulation: ViewEncapsulation.None,
  providers: [ NotificationService ]
} )
export class BusinessUnitTreeComponent implements OnInit, OnChanges {

  @Input () active = false;
  @Input () reportingCapable = false;
  @Input () buId: string;
  @Output () selectBU = new EventEmitter<BusinessUnit> ();

  rootLevel: BusinessUnit[];
  children: BusinessUnit[];
  levels: BusinessUnit[][];
  selected: BusinessUnit;
  inFocus: BusinessUnit;
  inputId: string;
  pageNumber = 1;
  totalPages = 1;
  buDetails: any;
  searchTerm = '';
  searchFieldValue: string;
  timeOut;
  selectedOnHover = false;
  buReferenceNumberPrompt: string;
  systemClientSelected: boolean;

  constructor(
    private businessUnitsService: BusinessUnitsService,
    private clientService: ClientService,
    private userService: UserService
  ) {
    this.systemClientSelected = this.userService.currentClient?.clientNumber?.toUpperCase() === 'SYSTEM';
  }

  ngOnInit(): void {
    this.businessUnitsService.getStartingLocations ()
      .pipe ( take ( 1 ) )
      .subscribe ( bu => {
        this.rootLevel = bu;
        this.levels = [ this.rootLevel ];
      } );
    this.buReferenceNumberPrompt =
      this.clientService.buTextSettingsByKey ( 'BU_REFERENCE_NUMBER_PROMPT' ) || Constants.buReferenceNumberPrompt;
  }

  ngOnChanges( changes: SimpleChanges ) {
    if ( changes?.buId && changes.buId?.currentValue && changes.buId.currentValue !== changes.buId.previousValue ) {
      this.inputId = this.buId;
      this.businessUnitsService.getBUAncestors ( this.buId )
        .pipe ( take ( 1 ) )
        .subscribe ( result => {
          this.levels = result.businessUnitAncestry.map ( bu => [ bu ] );
          if ( !this.levels.length ) {
            this.businessUnitsService.getStartingLocations ()
              .pipe ( take ( 1 ) )
              .subscribe ( bu => {
                this.rootLevel = bu;
                this.levels = [ this.rootLevel ];
              } );
          } else {
            this.levels.push ( [ result.selectedBusinessUnit ] );
          }
          this.selected = result.selectedBusinessUnit;
          this.businessUnitsService.getBUDetails ( this.selected.id )
            .toPromise ().then ( res => {
            this.buDetails = res;
          } );
          this.children = undefined;
        } );
    }
    if ( changes?.buId && !changes.buId?.currentValue ) {
      this.selected = undefined;
      this.levels = undefined;
      this.children = undefined;
      this.inFocus = undefined;
      this.buDetails = undefined;
      this.ngOnInit ();
    }
  }

  select( bu: BusinessUnit, currentLevel: number ) {
    this.inputId = undefined;

    if ( this.buDetails?.id !== bu?.id ) {
      this.getBUDetails ( bu );
    }

    if ( this.selected?.id === bu.id && this.children ) {
      return;
    }

    if ( currentLevel < this.levels?.length - 1 ) {
      this.levels = this.levels.slice ( 0, currentLevel + 1 );
      this.pageNumber = 1;
    }
    this.selected = bu;
    this.initChildren ();
    this.loadChildren ();
  }

  refineSearch() {
    this.searchTerm = this.searchFieldValue;
    this.initChildren ();
    this.loadChildren ();
  }

  selectChild( child: BusinessUnit ) {
    if ( child.hasChildren ) {
      this.searchFieldValue = undefined;
      this.searchTerm = undefined;
      this.levels.push ( [ child ] );
      this.selected = child;
      this.initChildren ();
      this.loadChildren ();
    }
  }

  loadChildren() {
    if ( !this.selected.hasChildren ) {
      return;
    }
    this.businessUnitsService
      .getBusinessUnitChildren (
        this.selected.id,
        this.reportingCapable,
        this.searchTerm,
        this.pageNumber,
        Constants.maxPageSize )
      .pipe ( take ( 1 ) )
      .subscribe ( result => {
        this.totalPages = result.totalPages;
        if ( this.pageNumber === 1 ) {
          this.children = result.records;
        } else {
          this.children = this.children.concat ( result.records );
        }
        this.pageNumber++;
      } );
  }

  initChildren() {
    this.children = undefined;
    this.pageNumber = 1;
    this.totalPages = 1;
  }

  async getBUDetails( bu: BusinessUnit ) {
    this.selectedOnHover = false;
    if ( bu?.id === this.buDetails?.id ) {
      this.buDetails = undefined;
      this.inFocus = undefined;
    } else {
      if ( bu?.id.toUpperCase() !== 'SYSTEM' ) {
        this.inFocus = bu;
        this.buDetails = undefined;
        this.buDetails = await this.businessUnitsService.getBUDetails ( bu.id )
          .toPromise ();
      }
    }
  }

  mouseOver( bu: BusinessUnit ) {
    if ( bu?.id !== this.buDetails?.id ) {
      if ( this.timeOut ) {
        clearTimeout ( this.timeOut );
      }
      this.timeOut = setTimeout ( async () => {
        await this.getBUDetails ( bu );
        this.selectedOnHover = true;
      }, 2000 );
    }
  }

  unsetTimeOut( bu: BusinessUnit ) {
    if ( this.timeOut ) {
      clearTimeout ( this.timeOut );
    }
    if ( this.buDetails && bu?.id === this.buDetails?.id && this.selectedOnHover ) {
      this.selectedOnHover = false;
      this.buDetails = undefined;
      this.inFocus = undefined;
    }
  }

  emitSelected( bu: BusinessUnit ) {
    this.levels = [ this.rootLevel ];
    if ( bu?.id.toUpperCase() !== 'SYSTEM' && ( !this.reportingCapable || bu?.reportingLocation ) ) {
      this.selectBU.emit ( bu );
    }
    if ( !bu ) {
      this.selectBU.emit ( null );
    }
    this.initChildren ();
    this.selected = undefined;
    this.inFocus = undefined;
    this.searchFieldValue = undefined;
    this.searchTerm = undefined;
  }
}
